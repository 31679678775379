.App {
  min-height: 100vh;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
  width: 264px;
  height: 70px;
  flex-shrink: 0;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.dot-pulse {
  position: relative;
  left: -9999px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

.outer {
  display: table;
  height: 100%;
  width: 100%;
 }
 .middle {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  width: 100%;
 }
 .loading-circle {
  height: 250px;
  margin: 0px auto;
  position: relative;
  width: 250px;
 }
 .percent {
  color: #000;
  display: block;
  font-size: 36px;
  margin: -22px 0px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
 }
 .circle1 {
  /* background-color: #333; */
  border: 5px solid;
  border-color: #0B5ED7 transparent;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 16px #000;
  -moz-box-shadow: 0px 0px 16px #000;
  box-shadow: 0px 0px 16px #000;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
 }
 .circle2 {
  /* background-color: #333; */
  border: 5px solid;
  border-color: #0B5ED7 transparent;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 16px #000;
  -moz-box-shadow: 0px 0px 16px #000;
  box-shadow: 0px 0px 16px #000;
  height: 80%;
  left: 10%;
  position: absolute;
  top: 10%;
  width: 80%;
 }
 .rotate-right {
  -webkit-animation: rotate 2s linear 0s infinite reverse;
  -moz-animation: rotate 2s linear 0s infinite reverse;
  animation: rotate 2s linear 0s infinite reverse;
 }
 .rotate-left {
  -webkit-animation: rotate 2s linear 0s infinite normal;
  -moz-animation: rotate 2s linear 0s infinite normal;
  animation: rotate 2s linear 0s infinite normal;
 }
 
 @-webkit-keyframes rotate {
  from {
   -webkit-transform: rotate(0deg);
  }
  to {
   -webkit-transform: rotate(360deg);
  }
 }
 @-moz-keyframes rotate {
  from {
   -moz-transform: rotate(0deg);
  }
  to {
   -moz-transform: rotate(360deg);
  }
 }
 @keyframes rotate {
  from {
   transform: rotate(0deg);
  }
  to {
   transform: rotate(360deg);
  }
 }

 .br {
  border-radius: 8px;
  margin-bottom: 20px;
}
.w80 {
   width: 50%;
  height: 15px !important;
}
.card {
  border: 2px solid #fff;
  box-shadow:0px 0px 10px 0 #a9a9a9;
  padding: 15px 40px;
  width: 80%;
  margin: 50px auto;
}
.wrapper {
  padding: 0;
  margin: 0;
  width: 45px !important;
  animation: fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
}
.wrapperxl {
  padding: 0;
  margin: 0;
  width: 300px !important;
  animation: fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.profilePic {
  height: 45px;
  width: 45px;
}
.profilePicxl {
  height: 300px;
  width: 300px;
}
.comment {
  height: 10px;
  background: #777;
  margin-top: 20px;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}


.animate {
   animation : shimmer 2s infinite linear;
   background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.profilePic-container {
  display: flex !important;
  justify-content: flex-start;
  gap: 20px !important;
}

.activePaymentType {
  /* border: 2px solid #0057FF !important; */
  border: 1px solid var(--black, #000);
  box-shadow: 0px 0px 4px 0px #000;
}

.activeBank {
  border: 2px solid #0057FF !important
}
.error {
  border: 1px solid red;
}
.phoneInput {
  padding: 1rem .5rem !important
}

.refreshCcw {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 256px;
  height: 256px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgba(255, 255, 255, 0.7); /* White background with some opacity */
  backdrop-filter: blur(10px);
  
}

.successParent {
  width: 100%;
  height: 80vh;
display: flex;
justify-content: center;
align-items: center;
}

.successDiv {
  display: flex;
  width: 539px;
  /* padding: 32px; */
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid #000;
  background: #FFF;
  /* margin-top: 2rem; */
}
.successTitle {
  color: #000;
  font-size: 30px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
}
.successNoti {
  color: #000;
  font-size: 16px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
}
.btnstyle {
  border-radius: 12px;
  /* background: #000; */
  color: white !important;
  padding: 8px 32px;
  border-radius: 12px;
  background: var(--opacity-black-60, rgba(0, 0, 0, 0.60));

}

.paymentParentBox:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  border-color: blue !important;
  transform: scale(1.01);
}

.activetag {
  position: relative;
  width: calc(50% - 10px);
  border-radius: 8px;
  border: 1px solid var(--black, #000);
  background: white;
  /* stroke */
  box-shadow: 0px 0px 4px 0px #000;
}

.normaltag{
  /* width: 252px; */
  width: calc(50% - 10px);
  background: white;
  /* background: white !important;
  border-bottom: 1px solid var(--opacity-black-30, rgba(0, 0, 0, 0.30)) !important; */
  border-radius: 8px;
  border: 1px solid var(--opacity-black-30, rgba(0, 0, 0, 0.30));
}

@media screen and (min-width: 993px) {
  .merchantInfo {
    position: sticky;
    top: 10px;
    width: calc(35% - 15px);
  }
  .successNoti {
    font-size: 24px;
  line-height: 32px;

  }
  .paymentMethodSide {
    width: calc(65% - 15px);
  }
}
@media screen and (max-width: 500px) {
 .qrdiv {
  display: flex !important;
  justify-content: center !important;
 }
 .wrapperxl {
  margin: auto !important;
 }
 .selectpaymentTitle {
  font-size: 28px !important;
 }
 .activetag {
  /* width: 130px; */
  width: calc(50% - 10px)
}

.normaltag{
  /* width: 130px; */
  width: calc(50% - 10px)
}
}


.selectpaymentTitle {
  color: #000;
  font-family: Nunito Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 150% */
}

.paymentname {
  color: #000;
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.noBorderBottom {
  border-bottom: none !important;
}

.border-bottom {
  border-bottom: 1px solid red;
}

.languagesdiv {
  margin-right: 1rem;
  /* padding: 0.5rem 1.4rem; */
  padding-right: 0.5rem;
  /* padding-top: 0.2rem; */
  position:relative !important;

  
}
.beforeicon {
  /* background-color: black; */
  /* color: rgb(247, 238, 238); */
  border-color: black;
  border-radius: 5px;
  padding: 0.5rem 2rem;
  padding-right: 0;
  font-weight: 500;
  z-index: 1;
}

.languagesdiv::before {
  content: "\f0ac";
  font-family: "fontawesome";
  font-size: 1.4rem;
  /* color: white; */
  position: absolute;
  top: 50%;
  left: 8%;
  transform: translateY(-50%);
}